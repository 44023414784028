import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { IntlProvider } from './Utils/reactIntl';
import { persistor, store } from './Redux/store';
import RoutesWrapper from './Router';
import English from './Lang/en.json';
import French from './Lang/fr.json';
import Constants from './Helpers';
import { ToastContainer } from 'react-toastify';

import { SecretsManagerClient, GetSecretValueCommand } from '@aws-sdk/client-secrets-manager';
import { useEffect } from 'react';
import './Assets/styles/globals.css';
import 'react-quill/dist/quill.snow.css';
import './Assets/styles/quill.scss';

const locale = navigator.language;
// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#2B2B2B',
    },
    secondary: {
      main: '#7CFBD9',
    },
  },
});
let lang: any;
if (locale === Constants.LOCALE_ID.en) {
  lang = English;
} else if (locale === Constants.LOCALE_ID.fr) {
  lang = French;
}
function App() {
  const secret_name = 'Slice-UAT-Admin-SM';

  const client = new SecretsManagerClient({
    region: 'me-central-1',
  });

  let response;

  const getEnvFile = async () => {
    try {
      response = await client.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      );
    } catch (error) {
      // For a list of exceptions thrown, see
      // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
      throw error;
    }
    const secret = response.SecretString;
  };

  useEffect(() => {
    getEnvFile();
  }, []);

  return (
    <IntlProvider locale={locale} messages={lang}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <ToastContainer />
            <BrowserRouter>
              <RoutesWrapper />
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </IntlProvider>
  );
}
export default App;
